.container {
  margin: 0px 20px;
}

.profile-pic {
  border-radius: 8px;
  max-width: 60%;
  margin-top: 8px;
}

h3 {
  margin-bottom: 8px;
}